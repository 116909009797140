import { ApolloClient, HttpLink, InMemoryCache } from 'apollo-boost'
import fetch from 'isomorphic-fetch'

export const client = new ApolloClient({
  link: new HttpLink({
    uri: `https://${process.env.GATSBY_SHOP_NAME}.myshopify.com/api/graphql`,
    credentials: 'same-origin',
    headers: {
      'X-Shopify-Storefront-Access-Token': process.env.GATSBY_SHOP_ACCESS_TOKEN
    },
    fetch: (uri, options) => {
      return fetch(uri, options)
    }
  }),
  cache: new InMemoryCache()
})
