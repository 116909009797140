import React from 'react'
import { node } from 'prop-types'
import { ApolloProvider } from 'react-apollo'
import { client } from './src/lib/apolloClient'

export const wrapRootElement = ({ element }) => (
  <ApolloProvider client={client}>{element}</ApolloProvider>
)

wrapRootElement.propTypes = {
  element: node
}
